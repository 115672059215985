import React from 'react'
import '../css/contacto.css'

//import icon
import iconfb from '../icons/icon-fb-70.png'
import iconig from '../icons/icon-ig-75.png'
import iconyt from '../icons/icon-yt-75.png'
import { ContactForm } from './ContactForm'

export const Contacto = () => {
    return (
        <section className="cont_contacto">
            <div className="row_info row">
                <div className="info_consulta_col col-xl-6 col-md-6 col-sm-12">
                    <div className="info_consulta">
                        <div className="titulo_esperamos">¡Esperamos tu consulta!</div>
                        <div className='subtitle_contact'>
                            Si tenes alguna duda o inquietud escribinos por acá y nos estaremos contactando a la brevedad!
                        </div>
                        <ContactForm />

                    </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-12" style={{ paddingTop: '4rem' }}>
                    <iframe title='contact-map' className="mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d105073.33125124883!2d-58.515706874814555!3d-34.61575126741337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcca3d05457fbb%3A0xe160f4fce7f7c017!2sCdad.%20Aut%C3%B3noma%20de%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1711500227918!5m2!1ses-419!2sar" style={{ border: 0, height: 430 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>



        </section >
    )
}