import { useState } from 'react';
import '../css/footer.css'

const NewsletterForm = ({ status, message, onValidated }) => {

    const [error, setError] = useState(null);
    const [email, setEmail] = useState(null);

    const handleFormSubmit = () => {

        setError(null);

        if (!email) {
            setError('Please enter a valid email address');
            return null;
        }

        const isFormValidated = onValidated({ EMAIL: email });

        // On success return true
        return email && email.indexOf("@") > -1 && isFormValidated;
    }

    const handleInputKeyEvent = (event) => {
        setError(null);
        // Number 13 is the "Enter" key on the keyboard
        if (event.keyCode === 13) {
            // Cancel the default action, if needed
            event.preventDefault();
            // Trigger the button element with a click
            handleFormSubmit();
        }
    }

    const getMessage = (message) => {
        if (!message) {
            return null;
        }
        const result = message?.split('-') ?? null;
        if ("0" !== result?.[0]?.trim()) {
            return message
        }
        const formattedMessage = result?.[1]?.trim() ?? null;
        return formattedMessage ? formattedMessage : null;
    }

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <input
                    onChange={(event) => setEmail(event?.target?.value ?? '')}
                    type="email"
                    placeholder="Email"
                    className="input_footer"
                    onKeyUp={(event) => handleInputKeyEvent(event)}
                />
                <button className="btn_sus" onClick={handleFormSubmit} >
                    SUSCRIBIRME!
                </button>
            </div>

            <div className="min-h-42px" >
                {'sending' === status ? <div className='pt-2'>Cargando...</div> : null}
                {'error' === status || error ? (
                    <div
                        className="text-red-700 pt-2"
                        style={{ color: 'red', fontWeight: 600 }}
                        dangerouslySetInnerHTML={{ __html: error || getMessage(message) }}
                    />
                ) : null}
                {'success' === status && 'error' !== status && !error && (
                    <div className="text-green-200 font-bold pt-2" style={{ color: 'green', fontWeight: 600 }} dangerouslySetInnerHTML={{ __html: message }} />
                )}
            </div>
        </div>
    );
}

export default NewsletterForm