import React from 'react'
import { Link } from 'react-router-dom'
import '../css/footer.css'

//import icon
import iconfb from '../icons/icon-fb-70.png'
import iconig from '../icons/icon-ig-75.png'
import iconyt from '../icons/icon-yt-75.png'
import icontk from '../icons/tik-tok.png'
import NewsletterFormFooter from './NewsletterFormFooter'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

const MAILCHIMP_URL = 'https://adiestramientopambo.us18.list-manage.com/subscribe/post?u=1753690ab6bcac557c5aab6e4&amp;id=d206c84b31&amp;f_id=00afc6e0f0';

export const Footer = () => {
    return (
        <section className="section_footer">

            <div className="footer_description">
                <div className='footer_grid'>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div style={{ width: '80%', paddingRight: 20 }}>
                            <div style={{ fontSize: 20, fontFamily: 'Poppins', fontWeight: 600, marginBottom: 10 }}>Newsletter</div>
                            <div style={{ fontSize: 15, fontFamily: 'Poppins', fontWeight: 400, marginBottom: 15 }}>Suscribite y enterate de todas nuestras novedades al instante</div>
                            <MailchimpSubscribe
                                url={MAILCHIMP_URL}
                                render={(props) => {
                                    const { subscribe, status, message } = props || {};
                                    return (
                                        <NewsletterFormFooter
                                            status={status}
                                            message={message}
                                            onValidated={formData => subscribe(formData)}
                                        />
                                    );
                                }}
                            />


                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column' }}>
                        <div style={{ fontSize: 20, fontFamily: 'Poppins', fontWeight: 600, marginBottom: 10, textAlign: 'center' }}>Seguinos en nuestras redes!</div>
                        <div className="img_redes">
                            <a href="https://www.instagram.com/adiestramientopambo/" target="_blank" rel="noopener noreferrer">
                                <img src={iconig} alt="icon-ig"></img>
                            </a>
                            <a href="https://www.tiktok.com/@adiestramientopambo" target="_blank" rel="noopener noreferrer">
                                <img src={icontk} alt="icon-tk"></img>
                            </a>
                            <a href="https://www.youtube.com/@adiestramientopambo1408" target="_blank" rel="noopener noreferrer">
                                <img src={iconyt} alt="icon-yt"></img>
                            </a>
                            <a href="https://www.facebook.com/adiestramientopambo/" target="_blank" rel="noopener noreferrer">
                                <img src={iconfb} alt="icon-fb"></img>
                            </a>
                        </div>
                    </div>

                    <div>
                        <div style={{ fontSize: 20, fontFamily: 'Poppins', fontWeight: 600, marginBottom: 10 }}>Información</div>
                        <ul className='info_list_footer'>
                            <div>
                                <Link to="/cursos/individuales">
                                    <li className="li_footer">Cursos</li>
                                </Link>
                                <Link to="/guarderia">
                                    <li className="li_footer">Guardería</li>
                                </Link>
                                <Link to="/deportes">
                                    <li className="li_footer">Deportes</li>
                                </Link>
                                <Link to="/historia">
                                    <li className="li_footer">Nuestra historia</li>
                                </Link>
                            </div>
                            <div style={{ marginLeft: '2rem' }}>
                                <Link to="/educar">
                                    <li className="li_footer">¿Por qué educar a tu perrito?</li>
                                </Link>
                                <Link to="/testimonios">
                                    <li className="li_footer">Testimonios</li>
                                </Link>
                                <Link to="/contacto">
                                    <li className="li_footer">Contacto</li>
                                </Link>
                            </div>

                        </ul>
                    </div>

                </div>
                <div className="linea"></div>
                <div style={{ marginTop: 15 }}>Copyright © 2024 All rights reserved</div>
            </div>
        </section>
    )
}