import React, { useState } from 'react'
import emailjs from 'emailjs-com'
import iconfb from '../icons/icon-fb-70.png'
import iconig from '../icons/icon-ig-75.png'
import iconyt from '../icons/icon-yt-75.png'
import icontk from '../icons/tik-tok.png'

import '../css/form.css'

export const ContactForm = () => {

    const [mailValido, setMailValido] = useState(false)
    const [nombreValido, setNombreValido] = useState(false)

    const validacionNombre = () => {
        const nombre = document.querySelector("#nombre").value
        const inputBoxNombre = document.querySelector("._nombre")

        if (nombre !== "" && nombre !== " " && nombre !== null && nombre !== undefined) {
            setNombreValido(true)
            inputBoxNombre.classList.add("validado")
            inputBoxNombre.classList.remove("invalidado")
        } else {
            setNombreValido(false)
            inputBoxNombre.classList.remove("validado")
            inputBoxNombre.classList.add("invalidado")
        }

    }

    const validacionEmail = () => {
        var pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;

        const email = document.querySelector("#email")
        const inputBox = document.querySelector("._email")

        if (email.value.match(pattern)) {
            inputBox.classList.add("validado")
            inputBox.classList.remove("invalidado")
            setMailValido(true)
        } else {
            inputBox.classList.remove("validado")
            inputBox.classList.add("invalidado")
            setMailValido(false)
        }

    }

    function sendEmail(e) {

        let textarea = document.querySelector("#mensaje").value

        if (mailValido === true && nombreValido === true && textarea !== "" && textarea !== " " && textarea !== undefined) {
            e.preventDefault();

            emailjs.sendForm('service_2r66dwn', 'template_41hmmvn', e.target, 'JeBIC21zvUiebmCgH')
                .then((result) => {
                    console.log(result.text);
                    document.querySelector(".pos_mail").innerHTML = ''
                    document.querySelector(".pos_mail").innerHTML = "Mail enviado correctamente"
                    document.querySelector(".pos_mail").classList.add("pos_success")
                    document.querySelector(".pos_mail").classList.remove("pos_error")
                }, (error) => {
                    console.log(error.text);
                    document.querySelector(".pos_mail").innerHTML = ''
                    document.querySelector(".pos_mail").innerHTML = "Error al enviar el mail"
                    document.querySelector(".pos_mail").classList.add("pos_error")
                    document.querySelector(".pos_mail").classList.remove("pos_success")

                });

            e.target.reset()
        } else {
            e.preventDefault();

            console.log("MAIL INVALIDO PA")
            document.querySelector(".pos_mail").innerHTML = ''
            document.querySelector(".pos_mail").innerHTML = "Revise los valores ingresados"
            document.querySelector(".pos_mail").classList.add("pos_error")
            document.querySelector(".pos_mail").classList.remove("pos_success")
        }
    }

    return (
        <div className="section_form">
            <div className="cont_form">
                <form className="form" onSubmit={sendEmail}>
                    <div className="input_box _nombre">
                        <input className="input_form" id="nombre" name="nombre" type="text" onKeyUp={validacionNombre} placeholder='Nombre' autoComplete="off"></input>
                    </div>

                    <div className="input_box _email">
                        <input className="input_form" id="email" name="email" type="text" onKeyUp={validacionEmail} placeholder='Email' autoComplete="off"></input>
                    </div>

                    <textarea className="input_form textarea_form" id="mensaje" name="mensaje" placeholder='Mensaje' type="text"></textarea>

                    <div className="cont_btn_form">
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                            <a href="https://www.instagram.com/adiestramientopambo/" target="_blank" rel="noopener noreferrer">
                                <img src={iconig} alt="icon-ig" width={47} height={47}></img>
                            </a>
                            <a href="https://www.tiktok.com/@adiestramientopambo" target="_blank" rel="noopener noreferrer">
                                <img src={icontk} alt="icon-tk" width={47} height={47}></img>
                            </a>
                            <a href="https://www.youtube.com/@adiestramientopambo1408" target="_blank" rel="noopener noreferrer">
                                <img src={iconyt} alt="icon-yt" width={47} height={47}></img>
                            </a>
                            <a href="https://www.facebook.com/adiestramientopambo/" target="_blank" rel="noopener noreferrer">
                                <img src={iconfb} alt="icon-fb" width={47} height={47}></img>
                            </a>
                        </div>
                        <input type="submit" className="btn_enviar_form" value="Enviar"></input>
                    </div>
                    <div className="pos_mail pos_error pos_success"></div>
                </form>
            </div>
        </div>
    )
}