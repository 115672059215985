import React from 'react'
import '../css/modalLand.css'
import news from '../imgs/newsletter.png'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { NewsletterFormModal } from './NewsletterFormModal'

export const ModalLand = () => {

    const MAILCHIMP_URL = 'https://adiestramientopambo.us18.list-manage.com/subscribe/post?u=1753690ab6bcac557c5aab6e4&amp;id=d206c84b31&amp;f_id=00afc6e0f0';

    const handlerModal = () => {
        document.querySelector(".cont_modal").classList.add("subir_modal")
    }



    return (
        <div className="cont_modal">
            <i className="fas fa-times" onClick={() => { handlerModal() }}></i>
            <img src={news} alt="news" className="news"></img>
            <div className="titulo_modal">¿Ya te suscribiste a nuestro newsletter?</div>
            <div className="sub_modal">Suscribite y enterate de todas nuestras novedades al instante</div>
            <div className="cont_input" >
                <MailchimpSubscribe
                    url={MAILCHIMP_URL}
                    render={(props) => {
                        const { subscribe, status, message } = props || {};
                        return (
                            <NewsletterFormModal
                                status={status}
                                message={message}
                                onValidated={formData => subscribe(formData)}
                            />
                        );
                    }}
                />

            </div>
        </div>
    )
}