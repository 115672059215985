import React from 'react'
import guarderia from '../imgs/update/guarderia.png'
import '../css/guarderia.css'

export const Guarderia = () => {
    return (
        <section>
            <div className="cont_row cont_educar row">
                <div className="cont_info_curso col-xl-7 col-md-7 col-sm-12">
                    <div className="titulo_curso">Bienvenido a Nuestra Guarderia Canina personalizada!</div>

                    <div className="subtitulo_curso">Cuidamos a tu gordito en nuestra casa</div>

                    <div style={{ marginTop: '2rem' }}>
                        <div className='titulo bold'>¿Por qué elegir nuestra Guardería Canina?</div>

                        <div>
                            En nuestra guardería canina, tu perro estará en las mejores manos. Le brindaremos amor para que se sienta como en su hogar, respeto, un entorno familiar y mantendremos su rutina diaria.
                        </div>
                        <div> Nuestra casa será su segunda casa.</div>
                        <div style={{ marginTop: '2rem' }}>
                            <div className='titulo bold'>
                                Características de Nuestra Guardería Canina:
                            </div>

                            <ul>
                                <li><span className='bold'>Grupos extra reducidos</span></li>
                                <li><span className='bold'>Responsabilidad:</span> Como educadoras caninas, conocemos las necesidades básicas de tu gordo.</li>
                                <li><span className='bold'>Transparencia:</span> Tu perro estará libre por toda la casa, ya que no usamos caniles.</li>
                                <li><span className='bold'>Flexibilidad:</span> No hay un mínimo o máximo de tiempo de estadía. Además, disponemos de movilidad para llevarlo a una veterinaria en caso de ser necesario por cualquier tipo de urgencia.</li>
                                <li><span className='bold'>Actividades lúdicas y didácticas:</span> Ofrecemos actividades nutritivas a nivel mental para el enriquecimiento ambiental de tu perro.</li>
                                <li><span className='bold'>Caminatas por espacios verdes:</span> Llevamos a cabo caminatas para que tu perro disfrute del aire fresco y del ejercicio al aire libre.</li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div className="cont_img_curso col-xl-5 col-md-5 col-sm-12">
                    <img className="foto_curso_100" src={guarderia} alt="fotoperrito"></img>
                </div>
            </div>
            <div className="cont_row row">
                <div className="col-xl-6 col-md-6 col-sm-12" style={{ textAlign: 'initial' }}>
                    <div style={{ marginBottom: '2rem' }}>
                        <div className='titulo_segmento bold'>
                            Requisitos para Ingresar a Nuestra Guardería Canina:
                        </div>

                        <div>
                            Para la seguridad y salud de los perritos, tu gordito debe tener todas las vacunas y desparasitaciones al día, incluida la vacuna de la tos de las perreras. En cuanto a la higiene, tráelo bañado y envíanos su comprimido contra pulgas para que se la proporcionemos ni bien entre a la casa para garantizar sanidad y cuidado.
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-12" style={{ textAlign: 'initial', paddingLeft: '2rem' }}>

                    <div style={{ marginBottom: '2rem' }}>
                        <div className='titulo_segmento bold'>
                            Día de Adaptación en Nuestra Guardería Canina personalizada:
                        </div>
                        <div>
                            Este día será importante para que tu peque pueda adaptarse a nuestra rutina, a la compañía de nuestras perras y su comportamiento ante este nuevo hogar en el cual permanecerá unos días. Es un requisito que tu perrito esté castrado y tenga un excelente comportamiento para poder mantener un ambiente de armonía en casa.
                        </div>
                    </div>

                </div>
            </div>
            <div style={{ marginBottom: '4rem' }}>
                <div className='end-message'>
                    Consúltanos por traslados desde CABA a Canning.
                </div>
                <div className='end-message'>
                    Vacantes limitadas
                </div>

                <div className='end-message'>
                    Esperamos tu consulta!
                </div>
            </div>
        </section>
    )
}
