import React from 'react'
import historia from '../imgs/update/historia.png'
import arrow from '../icons/arrow.png'
import '../css/historia.css'

export const Historia = () => {
    return (
        <section>
            <div className="cont_curso cont_educar cont_historia_presentacion row">
                <div className="cont_info_curso col-xl-7 col-md-7 col-sm-12">
                    <div className="titulo_curso">Hola!</div>

                    <div className="cont_texto">
                        <div className="texto">Soy Yanina Emilce Diaz, CEO y fundadora de la Escuela Canina Pambo.</div>
                        <div className="texto">Me adentré al mundo de la educación canina y el adiestramiento canino allá por el 2010. Sin mucha experiencia en ese entonces, aunque con una gran motivación intrínseca logre especializarme en resolver problemas comportamentales con grandes guías nacionales e internacionales dedicados al adiestramiento canino y educación canina. Dentro de Argentina y viajando por el exterior.</div>
                        <div className="texto">Fui creando mi propia receta para generar un bienestar canino que permita la mejor convivencia que una familia pueda tener.</div>
                    </div>

                    <div className="cont_texto">
                        <div className="texto">Hoy, luego de algunos años, ya no estoy sola. Junto con Giselle Norambuena conformamos un pequeño equipo de trabajo unidas por la misma causa.</div>
                        <div className='texto'>Nuestro objetivo es ayudar a través de la educación canina en positivo a alcanzar el equilibrio entre educación y amor que cada perro necesita, para lograr una convivencia saludable con su familia. Brindando así clases en varios países del mundo.</div>
                    </div>

                </div>
                <div className="cont_img_curso col-xl-5 col-md-5 col-sm-12">
                    <img className="foto_curso_100" src={historia} alt="fotoperrito"></img>
                </div>
            </div>
            <div className='cont_curso cont_educar row'>
                <div className="cont_info_curso col-xl-6 col-md-6 col-sm-12">
                    <div className="cont_texto">
                        <div className="texto bold arrow_text">
                            <span>
                                <img src={arrow} alt="arrow" width={35}></img>
                            </span>
                            <span style={{ marginLeft: '.5rem', fontSize: '20px' }}>Nuestra formación y experiencia:</span>
                        </div>
                    </div>

                    <div className="cont_texto">
                        <div className="texto">Somos profesionales matriculadas, con certificaciones y especializaciones en Argentina y España principalmente.</div>
                        <div className="texto">Nos entrevistaron en diferentes medios de comunicación a lo largo de los años.</div>
                        <div className="texto">Realizamos cursos de formación como; “Dog emotion and cognition” de la Duke University en el año 2019. EEUU.</div>
                        <div className="texto">Participamos en una campaña publicitaria para una reconocida marca de indumentaria holandesa llamada SHOEBY con Pampa (una de nuestras gorditas). También fuimos parte de grabaciones de comerciales.</div>
                        <div className="texto">Participamos del Congreso Internacional de Olfato en perros de búsqueda y rescate en el año 2019.</div>
                        <div className="texto">Realizamos capacitaciones sobre educación canina en Orlando (EEUU) en el año 2017.</div>
                        <div className="texto">Somos juezas internacionales de la Liga USDDN.</div>
                    </div>
                </div>
                <div className="cont_info_curso col-xl-6 col-md-6 col-sm-12" style={{ paddingLeft: 30 }}>
                    <div className="cont_texto">
                        <div className="texto bold arrow_text">
                            <span>
                                <img src={arrow} alt="arrow" width={35}></img>
                            </span>
                            <span style={{ marginLeft: '.5rem', fontSize: '20px' }}>Nuestra metodología de trabajo:</span>
                        </div>
                    </div>
                    <div className='cont_texto'>
                        <div className='texto'>Enseñamos a traves de técnicas de neuro-educación  y refuerzos positivos. El mismo se basa en la motivación a través de reforzadores o estímulos (comida, juguetes, recompensas, etc).</div>
                        <div className='texto'>El perro percibe el aprendizaje como un juego y es mucho más fácil a la hora de modificar sus problemas o incorporar nuevas conductas, ya que estará predispuesto y entusiasmado.</div>
                        <div className='texto'>A la hora de modificar una conducta formamos un equipo: el perro, la familia y nosotras como educadoras.</div>
                    </div>
                </div>



            </div>

        </section >

    )
}